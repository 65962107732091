





































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import Empty from "@/components/empty.vue";
import { ShujuquanxianList, ChangeShujuquanxian } from "@/request/manage";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Empty,
  },
})
export default class Name extends Vue {
  private window: any = window;
  private libs: any[] = [];
  private ifShowEmpty: any = false;
  /**
   * @description 获取数据权限列表
   */
  private getShujuquanxianList() {
    ShujuquanxianList(this).then((data: any) => {
      this.libs = data;
      if (this.libs.length === 0) {
        this.ifShowEmpty = true;
      } else {
        this.ifShowEmpty = false;
      }
    });
  }
  /**
   * @description 库权限改变
   */
  private changeLib(e: any, item: any) {
    const params: any = {
      project_id: item["project_id"],
      权限: e,
    };
    ChangeShujuquanxian(this, params).then((data: any) => {
      this.$message.success("修改成功");
      this.getShujuquanxianList();
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.getShujuquanxianList();
  }
}
